@import "../../../../assets/variables.scss";
@keyframes cube-transition {
    25% {
        transform: translateX(50px) scale(0.5) rotate(-90deg); }
    50% {
        transform: translate(50px, 50px) rotate(-180deg); }
    75% {
        transform: translateY(50px) scale(0.5) rotate(-270deg); }
    100% {
        transform: rotate(-360deg); }
}
.loader {
    transform: translate(-25px, -25px);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    &__cube{
        animation-fill-mode: both;
        width: 10px;
        height: 10px;
        top: -5px;
        left: -5px;
        background-color: $vdjcolor;
        animation: cube-transition 1.6s 0s infinite ease-in-out;
        &:last-child {
                animation-delay: -0.8s;
        }
    }
}

@import "./assets/variables.scss";
.app{
    height: 100vh;
    overflow: hidden;
}
.page-anim{
    &-enter{
        opacity: 0;
        &-active{
            opacity: 1;
            transition: opacity .3s;
        }
    }
    &-exit{
        opacity: 1;
        &-active{
            opacity: 0;
            transition: opacity .3s;
        }
    }
}

.page{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: calc(100vh - 50px);
    padding: $pagePadding-v $pagePadding-h;
    box-sizing: border-box;
    transition-property: padding height;
    &Container{
        position: relative;
    }
    &.noPadding{
        padding: 0;
    }
}


.mainContent{
    display: flex;
}
.pageContainer{
    position: relative;
    flex: 1;
    overflow: auto;
}
.accessDenied{
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &__img{
        max-width: 200px;
        margin: auto;
        img{
            width: 100%;
        }
    }
    &__text{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        margin-top: 30px;
        .stateIcon{
            height: 50px;
        }
        &--text{
            margin: 0 10px;
            text-align: center;
        }
    }
}
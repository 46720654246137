.userInfos{
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    &__title{
        font-size: 1.5rem;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #666;
    }
}
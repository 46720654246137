@import "../../../../../../../../../assets/variables.scss";
.langList{
    position: fixed;
    transform: translateY(65%);
    background: #fff;
    @include shadow;
    border-radius: 5px;
    overflow: hidden;
    transition: max-height .3s;
    &__item{
        display: flex;
        align-items: center;
        padding: 5px 12px;
        transition: background-color .15s;
        font-size: 1.1rem;
        &--flag{
            height: 16.5px;
            margin-right: 7px;
            img{
                height: 100%;
            }
        }
        &:hover{
            background-color: rgb(231, 231, 231);
        }
        &:first-child{
            padding-top: 8px;
        }
        &:last-child{
            padding-bottom: 8px;
        }
    }
}
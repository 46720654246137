@import "../../../../assets/variables.scss";
.input{
    input:not([type='submit']), textarea{
        outline: 0;
        background-color: #f2f2f2;
        width: 100%;
        box-shadow: inset 1px 1px 1px 0 rgba(0,0,0,.2), inset -1px -1px 1px 0 rgba(0,0,0,.2);
        border: 0;
        margin: 7px 0;
        padding: 15px;
        box-sizing: border-box;
        font-size: 14px;
    }
    &.submit{
        input{
            font-size: 1.3rem;
            border: none;
            background-color: $vdjcolor;
            width: 100%;
            height: 100%;
            padding: 15px;
            color: #fff;
            outline: 0;
            cursor: pointer;
        }
    }
}
@import "../../../../assets/variables.scss";

.header{
    @include shadow;
    height: 50px;
    display: flex;
    justify-content: space-between;
    z-index: 10001;
    background-color: #fff;
    position: relative;
    transition: height .2s;
    
    &.hidden{
        height: 0;
        overflow: hidden;
    }
    .flex{
        display: flex;
        align-items: center;
    }
    .navigationButton{
        margin-left: 15px;
    }
    .userPart{
        display: flex;
        align-items: center;
        &__info{
            display: flex;
            &--name{
                font-size: 1.2rem;
            }
            &--arrow{
                font-size: 1.15rem;
                margin-top: 1px;
                margin-left: 10px;
                cursor: pointer;
                transition: transform .3s ease-in-out;
                &.active{
                    transform: rotateX(-180deg);
                }
            }
        }
        &__icon{
            height: 100%;
            padding: 10px 20px;
            font-size: 2rem;
        }

    }
}

.header-anim{
    &-enter{
        height: 0;
        &-active{
            height: 50px;
            transition: height .3s;
        }
    }
    &-exit{
        height: 50px;
        &-active{
            height: 0;
            transition: height .3s;
        }
    }
}
@import "../../../assets/variables.scss";
.navigation{
    height: calc(100vh - 50px);
    background: #2d3238;
    background: linear-gradient(180deg, #202328 0%, #2d3238 2%, #2d3238 26%);
    color: #fff;
    box-shadow: 0 0 8px rgba(0,0,0,.65);
    width: 250px;
    overflow: hidden;
    user-select: none;
    word-break: keep-all;
    &__padding{
        padding: 20px;
        box-sizing: border-box;
    }
    &__link{
        font-size: 1.2rem;
        margin: 20px 0;
        &--main{
            display: flex;
            align-items: center;
            &--icon{
                margin-right: 7px;
            }
            &-text{
                span{
                    white-space: nowrap;
                }
            }
        }
    }
    &__extendedLink{
        overflow: hidden;
        &--title{
            display: flex;
            justify-content: space-between;
            &--icon{
                cursor: pointer;
                transform: rotate(-90deg);
                transition: transform .15s ease-in-out;
            }
        }
        &--sublink{
            transition: max-height .3s ease-in-out;
            font-size: 1.1rem;
            padding-left: 12px;
            margin-left: 30px;
            border-left: 2px solid #fff;
            a{
                display: block;
                padding-top: 5px;
                span{
                    white-space: nowrap;
                }
            }
        }
        &.deployed .navigation__extendedLink{
            &--title--icon{
                transform: rotate(0);
            }
            &--sublink{
                max-height: 50px;
            }
        }
        &.hidden .navigation__extendedLink--sublink{
            max-height: 0;
        }
    }
}


.navigation-animation{
    &-enter{
        max-width: 0;
        &-active, &-done{
            @media (min-width: $breakpoint) {
                max-width: 250px;
            }
            max-width: 400px;
            transition: max-width .4s ease-in-out;
            transition-property: max-width;
        }
    }
    &-exit{
        @media (min-width: $breakpoint) {
            max-width: 250px;
        }
        max-width: 400px;
        &-active, &-done{
            max-width: 0;
            transition: .4s ease-in-out;
            transition-property: max-width;
        }
    }
}


@import '../../../../../../assets/variables.scss';
.options{
    position: absolute;
    width: 300px;
    top: 60px;
    right: 15px;
    border-radius: 7px;
    background-color: #fff;
    overflow: hidden;
    @include shadow;
    user-select: none;
    transition: max-height .3s;
    z-index: 1001;
    &__item{
        padding: 10px 20px;
        border-bottom: 1px solid rgb(196, 196, 196);
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 1.2rem;
        &--icon{
            min-width: 26px;
            margin-right: 6px;
        }
        &:first-child{
            padding-top: 15px;
        }
        &:last-child{
            padding-bottom: 15px;
            border: none;
        }
    }
}
.navIcon{
    width: 37px;
    height: 28px;
    position: relative;
    cursor: pointer;
    span{
        display: block;
        position: absolute;
        height: 6px;
        width: 100%;
        background: #000;
        border-radius: 6px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .30s ease-in-out;
        transition-property: top, width, left, transform;
        &:nth-child(1){
            top: 0%;
        }
        &:nth-child(2), &:nth-child(3){
            top: 40%;
        }
        &:nth-child(4){
            top: 80%;
        }
    }
    &.active span{
        &:nth-child(1){
            top: 40%;
            width: 0%;
            left: 50%;
        }
        &:nth-child(2){
            transform: rotate(45deg);
        }
        &:nth-child(3){
            transform: rotate(-45deg);
        }
        &:nth-child(4){
            top: 40%;
            width: 0%;
            left: 50%;
        }
    }
}
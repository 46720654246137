@import '../../../../assets/variables.scss';
.authInterface{
    max-width: 560px;
    margin: auto;
    margin-top: 100px;
    @include shadow;
    text-align: center;
    padding: 45px;
    &__password{
        position: relative;
        &--button{
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-40%);
            cursor: pointer;
        }
    }
    &__stayConnected{
        margin: 10px 0;
        label{
            margin-left: 4px;
            user-select: none;
        }
    }
    &__result{
        &.hidden{
            height: 0;
        }
        display: flex;
        justify-content: center;
        transition: height .4s;
        height: 30px;
        margin-top: 15px;
        &--text{
            align-self: center;
            font-size: 18px;
            margin: 0 10px;
        }
    }
}
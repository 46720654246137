.lang{
    display: flex;
    align-items: center;
    position: relative;
    &__svg{
        height: 16.5px;
        margin-left: 4px;
        img{
            height: 100%;
        }
    }
    &__arrow{
        cursor: pointer;
        margin-left: 8px;
    }
}
.options__item.lang__noClick{
    cursor: auto;
}
@mixin shadow{
    box-shadow: 0 0 5px rgba(0, 0, 0,.15), 0 0 10px rgba(0, 0, 0,.25);
}
@mixin light-shadow{
    box-shadow: 0 0 5px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.18);
}
@mixin inset-shadow{
    box-shadow: 0 0 5px rgba(0, 0, 0,.15) inset, 0 0 10px rgba(0, 0, 0,.25) inset;
}
$main-color: #000;
$main-background-color: #fff;
$nav-color: #fff;
$nav-background-color: #121212;
$vdjcolor: #4CAF50;
$breakpoint : 950px;
$button-background: #4CAF50;
$vdjcolortextwhitebg: #95CF95;
$mainbackgroundcolor-vdj : #d3eed3;
$button-color: #fff;

$pagePadding-v: 20px;
$pagePadding-h: 25px; 
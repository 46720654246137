.firstplan{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.firstplan-anim{
    &-enter{
        opacity: 0;
        &-active{
            opacity: 1;
            transition: opacity .2s;
        }
    }
    &-exit{
        opacity: 1;
        &-active{
            opacity: 0;
            transition: opacity .2s;
        }
    }
}
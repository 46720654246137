@import "../../../assets/variables.scss";
.popup{
    position: absolute;
    bottom: 10px;
    right: 15px;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
    &__item{
        padding: 18px 10px;
        padding-right: 30px;
        font-size: 1.05rem;
        margin-top: 15px;
        min-width: 250px;
        text-align: center;
        @include shadow;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        user-select: none;
        &--icon{
            width: 25px;
            margin-right: 10px;
        }
        &--times{
            position: absolute;
            top: 5px;
            right: 10px;
        }
        &.error{
            color: #FFF;
            background-color: #eb2610;
            .popup__item--icon .stateIcon.error{
                stroke: #fff;
            }
        }
        &.success{
            background-color: $button-background;
            .popup__item--icon .stateIcon{
                stroke: #fff;
            }
        }
    }
}

.popup-anim{
    &-enter{
        transform: translateX(130%) scaleY(0);
        &-active{
            transform: translateX(0) scaleY(1);
            transition: transform .3s;
        }
    }
    &-exit{
        transform: translateX(0) scaleY(1);
        &-active{
            transform: translateX(130%) scaleY(0);
            transition: transform .3s;
        }
    }
}
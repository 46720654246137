@import '../../../../assets/variables.scss';

.savedData{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    &__saveButton{
        align-self: center;
        font-size: 1.1rem;
        margin: 0 7px;
        &--round{
            cursor: pointer;
            padding: 3px;
            margin-bottom: 1px;
            border-radius: 50px;
            background-color: $button-background;
            color: $button-color;
            @include shadow;
        }
    }
    &__state{
        &--text{
            &--icon{
                margin-right: 5px;
                font-size: 1.2rem;
                &.green{
                    color: $button-background;
                }
                &.yellow{
                    color: orange;
                }
            }
        }
    }
}